@tailwind base;
@tailwind components;
@tailwind utilities;

.form-tick:checked {
  background-image: url('data:image/svg + xml,%3csvgviewBox="0 0 16 16"fill="white"xmlns="http://www.w3.org/2000/svg"%3e%3cpathd="M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z"/%3e%3c/svg%3e');
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.form-tick {
  background-color: green !important;
}

.ql-clipboard {
  display: none;
}

.ql-editor {
  outline: none;
}

.ql-editor:focus {
  outline: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.min-h-7 {
  min-height: 1.75rem;
}

.alice-carousel__stage-item * {
  line-height: 1.5 !important;
}

.react-colorful {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  width: 100% !important;
}

.react-colorful__last-control {
  border-radius: 0 !important;
}
button {
  -webkit-tap-highlight-color: transparent;
}

.dark #date-picker {
  color-scheme: dark;
}

#date-picker {
  color-scheme: light;
}

@layer components {
  .interactive-card {
    @apply transition-colors bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 dark:bg-dark-gray-800 dark:hover:bg-dark-gray-700 dark:focus:bg-dark-gray-700;
  }

  .subtext {
    @apply text-gray-600 dark:text-dark-gray-200;
  }

  .section-border {
    @apply pt-3 mt-8 mb-3 border-t flex-between dark:border-dark-gray-600;
  }
}

@font-face {
  font-family: 'Liberation Sans';
  src: local('Liberation Sans'),
    url(./fonts/LiberationSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Liberation Sans';
  src: local('Liberation Sans'),
    url(./fonts/LiberationSans-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Liberation Sans';
  src: local('Liberation Sans'),
    url(./fonts/LiberationSans-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Liberation Sans';
  src: local('Liberation Sans'),
    url(./fonts/LiberationSans-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

.stroke-width .react-colorful__alpha,
.color-picker .react-colorful__alpha,
.color-picker .react-colorful__hue {
  border-radius: 5px !important;
  height: 18px;
  margin-top: 10px;
}

.color-picker .react-colorful__saturation {
  border-radius: 5px !important;
  margin-top: 10px;
}

.stroke-width .react-colorful__hue,
.stroke-width .react-colorful__saturation {
  display: none;
}

.stroke-width .react-colorful {
  height: auto;
}

.stroke-width .react-colorful__pointer,
.color-picker .react-colorful__pointer {
  width: 20px;
  height: 20px;
}
